// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getRegistrations = createAsyncThunk('appRegistrations/getRegistrations', async () => {
    try {
        return await authAxios.get(`${apiEndpoint}/vehicle/registrations`)
    } catch (error) {
        console.log(error)
    }
})

export const appRegistrationsSlice = createSlice({
    name: 'appRegistrations',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRegistrations.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                } catch (error) {
                    console.log(error)
                }
            })
    }
})

export default appRegistrationsSlice.reducer
import axios from 'axios'
import tokenDefaultConfig from './tokenDefaultConfig'
import { toast } from 'react-toastify'
import { Fragment } from 'react'
import { X } from 'react-feather'

// ** Custom Components
import Avatar from '@components/avatar'

const ErrorToast = () => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="danger" icon={<X size={12} />} />
        <h6 className="toast-title">Error!</h6>
      </div>
      <small className="text-muted">11 Min Ago</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        👋 Jelly-o macaroon brownie tart ice cream croissant jelly-o apple pie.
      </span>
    </div>
  </Fragment>
)

export default class TokenService {
  // ** tokenConfig <= Will be used by this service
  tokenConfig = { ...tokenDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(tokenOverrideConfig) {
    this.tokenConfig = { ...this.tokenConfig, ...tokenOverrideConfig }
    console.log('token service constructor')

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getTokenValue()
        console.log(accessToken)

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.tokenConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        // ** const { config, response: { status } } = error
        console.log(error)
        const { config, response } = error
        // const originalRequest = config
        console.log(config)
        console.log(response)

        if (response && response.status === 400) {
          toast.error(response?.data?.detail || 'Something went wrong!')
          return Promise.reject(error)
        }

        // ** if (status === 401) {
        if (response && response.status === 401) {
          // if (!this.isAlreadyFetchingAccessToken) {
          //     this.isAlreadyFetchingAccessToken = true
          //     this.refreshToken().then(r => {
          //         this.isAlreadyFetchingAccessToken = false
          //
          //         // ** Update accessToken in localStorage
          //         this.setToken(r.data.accessToken)
          //         this.setRefreshToken(r.data.refreshToken)
          //
          //         this.onAccessTokenFetched(r.data.accessToken)
          //     })
          // }

          // history.push('/login')
          console.log('pushed to login')
          localStorage.removeItem('userData')
          localStorage.removeItem(this.tokenConfig.storageTokenKeyName)
          toast.error(<ErrorToast />, { icon: false, hideProgressBar: true })

          // todo: put this back
          window.location = '/login'
          return Promise.reject(error)

          // const retryOriginalRequest = new Promise(resolve => {
          //     this.addSubscriber(accessToken => {
          //         // ** Make sure to assign accessToken according to your response.
          //         // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          //         // ** Change Authorization header
          //         originalRequest.headers.Authorization = `${this.tokenConfig.tokenType} ${accessToken}`
          //         resolve(this.axios(originalRequest))
          //     })
          // })
          // return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.tokenConfig.storageTokenKeyName)
  }

  // Added because the getToken() method is returning a string with quotes included.
  // This messes up the creation of the Authorization header
  // This function does the same as getToken() but removes the quote sign from the edges of the token string
  getTokenValue() {
    const accessTokenFull = localStorage.getItem(
      this.tokenConfig.storageTokenKeyName
    )
    return accessTokenFull?.substring(1, accessTokenFull.length - 1)
  }

  getTokenType() {
    return this.tokenConfig.tokenType
  }

  getRefreshToken() {
    return localStorage.getItem(this.tokenConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.tokenConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.tokenConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.tokenConfig.loginEndpoint, ...args)
  }

  logout(...args) {
    return axios.post(this.tokenConfig.logoutEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.tokenConfig.registerEndpoint, ...args)
  }

  registerSubUser(...args) {
    return axios.post(this.tokenConfig.registerSubUserEndpoint, ...args)
  }

  changePassword(...args) {
    return axios.post(this.tokenConfig.changePasswordEndpoint, ...args)
  }

  // refreshToken() {
  //     return axios.post(this.tokenConfig.refreshEndpoint, {
  //         refreshToken: this.getRefreshToken()
  //     })
  // }
}

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../settings'

export const apiEndpoint = `${BASE_URL}/api`

function transformToDesiredFormat(data) {
  const transformedData = {}

  for (const key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      transformedData[key] = data[key].map((value) => ({
        label: value
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
        value
      }))
    }
  }

  return transformedData
}

export const getEnums = createAsyncThunk('configuration/getEnums', async () => {
  const response = await axios.get(`${apiEndpoint}/core/enums`)
  return {
    data: response.data
  }
})

export const getCurrencyList = createAsyncThunk(
  'configuration/getCurrencyList',
  async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/manager/currency`)
      return {
        data
      }
    } catch (e) {
      console.log(e)
    }
  }
)

export const getAllPdfs = createAsyncThunk(
  'configuration/getAllPdfs',
  async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/pdf/`)
      return {
        data
      }
    } catch (e) {
      console.log(e)
    }
  }
)

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {
    enums: [],
    currencyList: [],
    allPdfs: [],
    isLoading: false
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnums.fulfilled, (state, action) => {
        state.enums = transformToDesiredFormat(action.payload.data)
      })
      .addCase(getCurrencyList.fulfilled, (state, action) => {
        state.currencyList = action.payload.data
      })
      .addCase(getAllPdfs.fulfilled, (state, action) => {
        state.allPdfs = action.payload.data
        state.isLoading = false
      })
      .addCase(getAllPdfs.pending, (state) => {
        state.isLoading = true
      })
  }
})

export default configurationSlice.reducer

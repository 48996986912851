export const personInChargeType = {
  LEGAL_REPRESENTATIVES: 'legalRepresentatives',
  OTHER_REPRESENTATIVES: 'otherRepresentatives',
  DIRECTORS: 'directors',
  EXECUTIVE_BOARD: 'executiveBoard',
  BOARD_OF_DIRECTORS: 'boardOfDirectors',
  PROCURATORS: 'procurators'
}

export const INDIVIDUAL_DEFAULT_VALUES = {
  active: true,
  private_individual: true,
  primary_id: '',
  jmbg: '',
  id: '',
  name: '',
  surname: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  country: '',
  postcode: '',
  note: '',
  phone1: '',
  phone2: '',
  email: '',
  website: '',
  fax: ''
}
export const COMPANY_DEFAULT_VALUES = {
  // GENERAL
  active: true,
  private_individual: false,
  // BASIC INFO
  primary_id: '',
  pib: '',
  name: '',
  legal_name: '',
  note: '',
  // CONTACT INFO
  address1: '',
  address2: '',
  city: '',
  province: '',
  country: '',
  postcode: '',
  phone1: '',
  phone2: '',
  fax: '',
  email: '',
  website: ''
}
export const CONTACT_PERSON_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  role_title: '-',
  passport_id: '-',
  personal_id: '-',
  identity_card_id: '-',
  address: '-',
  country: '-',
  province: '-',
  city: '-',
  postcode: '-',
  phone1: '',
  phone2: '',
  email1: '',
  email2: '',
  note: '',
  restrictions: '',
  representative_type: 'CONTACT_PERSON'
}
export const PERSON_IN_CHARGE_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  role_title: '',
  passport_id: '',
  personal_id: '',
  identity_card_id: '',
  address: '',
  country: '',
  province: '',
  city: '',
  postcode: '',
  phone1: '',
  phone2: '',
  email1: '',
  email2: '',
  is_ceo: '',
  is_legal_representative: '',
  note: '',
  restrictions: ''
}

export const PREVIEW_DEFAULT_VALUES = {
  name: '',
  surname: '',
  legal_name: '',
  note: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  country: '',
  postcode: '',
  phone1: '',
  phone2: '',
  email: '',
  website: '',
  fax: ''
}

export const locationTypes = [
  { value: 'OFFICE', label: 'Office' },
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HEADQUARTERS', label: 'Headquarters' },
  { value: 'NONE', label: 'None' }
]

// ** Core Token Import
import useToken from '@src/@core/auth/token/useToken'

//**********************************************
//  POGRESNA IMPLEMENTACIJA:
//   - "useToken" prihvata default override
//     config objekat
//   - ne treba menjati default config objekat
//     koji se nalazi u @core, jer ce ga svaki
//     update templata pregaziti
//**********************************************

const { token } = useToken({})

export default token

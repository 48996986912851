// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getTyres = createAsyncThunk('appTyres/getTyres', async () => {
  try {
    return await authAxios.get(`${apiEndpoint}/vehicle/tyres`)
  } catch (error) {
    console.log(error)
  }
})

export const getAllLocations = createAsyncThunk(
  'appTyres/getAllLocations',
  async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/partner/location`)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

export const appTyresSlice = createSlice({
  name: 'appTyres',
  initialState: {
    data: [],
    locations: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTyres.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        try {
          state.locations = action.payload
        } catch (error) {
          console.log(error)
        }
      })
  }
})

export default appTyresSlice.reducer

// ** Auth Endpoints

import { BASE_URL } from "../../../settings"

// ***********************************************
//   NEMA POTREBE ZA MODIFIKOVANJEM OVOG OBJEKTA
// ***********************************************

export default {
  loginEndpoint: `${BASE_URL}/api/auth/login`,
  registerEndpoint: `${BASE_URL}/api/auth/registration`,
  registerSubUserEndpoint: `${BASE_URL}/api/auth/registration/staff`,
  changePasswordEndpoint: `${BASE_URL}/api/auth/password`,
  // refreshEndpoint: '/token/refresh-token',
  //refreshEndpoint: '${BASE_URL}/api/auth/login',
  // logoutEndpoint: '/token/logout',
  logoutEndpoint: `${BASE_URL}/api/auth/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Token',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}

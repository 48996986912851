import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  vehicles: [
    {
      id: 2,
      mark: 'Astra',
      model: 'Opel',
      engine_size: 1500,
      engine_power: 100,
      trim: '2.0 TDI',
      year: 2021,
      new: false,
      fuel_type: null,
      default_tyre: null,
      tyres: []
    },
    {
      id: 3,
      mark: 'Astra',
      model: 'Opel',
      engine_size: 1600,
      engine_power: 100,
      trim: '2.0 TDI',
      year: 2021,
      new: false,
      fuel_type: null,
      default_tyre: null,
      tyres: []
  }
  ]
}

// ------------------------------------------------
// GET: Return Vehicles List
// ------------------------------------------------
mock.onGet('/inventory/vehicle/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.vehicles.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(vehicle => { 
    return (
        vehicle.mark.toLowerCase().includes(queryLowered) ||
        vehicle.model.toLowerCase().includes(queryLowered) ||
        vehicle.trim.toLowerCase().includes(queryLowered) ||
        String(vehicle.id).toLowerCase().includes(queryLowered) ||
        String(vehicle.year).toLowerCase().includes(queryLowered) ||
        String(vehicle.fuel_type).toLowerCase().includes(queryLowered)
      )
    }
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.vehicles,
      total: filteredData.length,
      vehicles: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single vehicle
// ------------------------------------------------
mock.onGet(/\/api\/vehicle\/vehicles\/\d+/).reply(config => {
  // // Get event id from URL
  const vehicleId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const vehicleIndex = data.vehicles.findIndex(e => e.id === vehicleId)
  const responseData = {
    vehicle: data.vehicles[vehicleIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes vehicle
// ------------------------------------------------
mock.onDelete('/inventory/vehicle/delete').reply(config => {
  // Get vehicle id from URL
  let vehicleId = config.id

  // Convert Id to number
  vehicleId = Number(vehicleId)

  const vehicleIndex = data.vehicles.findIndex(t => t.id === vehicleId)
  data.vehicles.splice(vehicleIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/vehicle/clients').reply(() => {
  const clients = data.vehicles.map(vehicle => vehicle.client)
  return [200, clients.slice(0, 5)]
})

// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

// **********************************************
//         CUSTOM MADE RTQ QUERY FEATURE
// **********************************************
import { partnersApi } from '../views/partners/services/index'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(partnersApi.middleware)
  }
})

export { store }

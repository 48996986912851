import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { BASE_URL } from '../../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

//Vehicle sale:
export const getVehicleSales = createAsyncThunk('appVehicleSales/getVehicleSales', async () => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/business/sale_process`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const createVehicleSale = createAsyncThunk('appVehicleSales/createVehicleSale', async (data) => {
  try {
    const response = await authAxios.post(`${apiEndpoint}/business/sale_process`, data)
    return response.data
  } catch (error) {
    console.log(error)
  }
})

export const getVehicleSaleById = createAsyncThunk('appVehicleSales/getVehicleSaleById', async (id) => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/business/sale_process/${id}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const editVehicleSale = createAsyncThunk('appVehicleSales/editVehicleSale', async (data) => {
  try {
    const response = await authAxios.put(`${apiEndpoint}/business/sale_process/${data.id}`, data.data)
    return data.data
  } catch (error) {
    console.log(error)
  }
})
export const deleteVehicleSale = createAsyncThunk('appVehicleSales/deleteVehicleSale', async (id) => {
  try {
    const response = await authAxios.delete(`${apiEndpoint}/business/sale_process/${id}`)
    return id
  } catch (error) {
    console.log(error)
  }
})

//Sale offer:

export const getSaleOffersById = createAsyncThunk('appVehicleSales/getSaleOfferById', async (id) => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/business/sale_process/${id}/sale_offer`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const createSaleOffer = createAsyncThunk('appVehicleSales/createSellOffer', async (data) => {
  try {
    const response = await authAxios.post(`${apiEndpoint}/business/sale_process/${data.id}/sale_offer`, data.data)
    return data.data
  } catch (error) {
    console.log(error)
  }
})
export const deleteSaleOffer = createAsyncThunk('appVehicleSales/deleteSellOffer', async (data) => {
  try {
    const response = await authAxios.delete(`${apiEndpoint}/business/sale_process/${data.id}/sale_offer/${data.offerId}`)
    return data.offerId
  } catch (error) {
    console.log(error)
  }
})

export const appVehicleSalesSlice = createSlice({
  name: 'vehicleSales',
  initialState: {
    data: [],
    allData: [],
    selectedSale: {},
    saleOffers: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    //Vehicle Sales:
    .addCase(getVehicleSales.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(getVehicleSales.fulfilled, (state, action) => {
      state.allData = action.payload
      state.isLoading = false
    })
    .addCase(deleteVehicleSale.fulfilled, (state, action) => {  
      state.allData = state.allData.filter((item) => item.id !== action.payload)  
    })
    .addCase(getVehicleSaleById.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(getVehicleSaleById.fulfilled, (state, action) => {
      state.selectedSale = action.payload
      state.isLoading = false
    })
    .addCase(editVehicleSale.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(editVehicleSale.fulfilled, (state, action) => {
      state.selectedSale = action.payload
      state.isLoading = false
    })
    //Sale Offer
    .addCase(getSaleOffersById.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(getSaleOffersById.fulfilled, (state, action) => {
      state.saleOffers = action.payload
      state.isLoading = false
    })
    .addCase(createVehicleSale.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(createVehicleSale.fulfilled, (state, action) => {
      state.allData.push(action.payload)
      state.isLoading = false
    })
    .addCase(createSaleOffer.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(createSaleOffer.fulfilled, (state, action) => {
      state.saleOffers = [...state.saleOffers, action.payload]
      state.isLoading = false
    })
    .addCase(deleteSaleOffer.fulfilled, (state, action) => {
      state.saleOffers = state.saleOffers.filter((offer) => offer.id !== action.payload)
    })
  }
})

export default appVehicleSalesSlice.reducer
// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getReminders = createAsyncThunk('appReminders/getReminders', async () => {
    try {
        return await authAxios.get(`${apiEndpoint}/remainder/`)
    } catch (error) {
        console.log(error)
    }
})

export const acknowledgeRemainder = createAsyncThunk(
    'appReminders/acknowledgeRemainder',
    async ({remainderId}) => {
        try {
            const response = await authAxios.post(`${apiEndpoint}/remainder/acknowledge/${remainderId}`)
            return ({
                remainderId
            })
        } catch (error) {
            console.log(error)
        }
})

export const appRemindersSlice = createSlice({
    name: 'appReminders',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getReminders.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                } catch (error) {
                    console.log(error)
                }
            })
            .addCase(acknowledgeRemainder.fulfilled, (state, action) => {
                const remainderIdx = state.data.findIndex(r => r.id === action.payload.remainderId)
                state.data[remainderIdx].acknowledge = true
            })
    }
})

export default appRemindersSlice.reducer
// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getCasco = createAsyncThunk('appCasco/getCasco', async () => {
    try {
        return await authAxios.get(`${apiEndpoint}/vehicle/casco`)
    } catch (error) {
        console.log(error)
    }
})

export const appCascoSlice = createSlice({
    name: 'appCasco',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCasco.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                } catch (error) {
                    console.log(error)
                }
            })
    }
})

export default appCascoSlice.reducer
// ** Token Service Import
import TokenService from './tokenService'

// ** Export Service as useToken
export default function useToken(tokenOverrideConfig) {
    const token = new TokenService(tokenOverrideConfig)

    return {
        token
    }
}
